require("fslightbox");

import 'index.scss';

import $ from "jquery";

$(document).ready(function() {
	
	$('#menu_btn').click(function() {

		$(this).toggleClass('open').parent().toggleClass('open');

	});

});